import React, { Suspense, useCallback } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { Layout } from '../components/Layout/Layout';
import { FullPageCentered } from '../theme/styles/styles';
import { Spinner } from '../theme/components/Spinner';
import { ProtectedRoute } from './components/ProtectedRoute';
import { lazyRetry } from '../utils/lazyRetry';
import { usePermissions } from '../hooks/usePermissions';
import { NotFound } from './components/NotFound';
import { PropertyIDQueryParameterName } from '../constants';
import { PropertyRedirectRoute } from './components/PropertyRedirectRoute';
import { RestrictionsViewType } from '../pages/Restrictions/types';
import { FeatureFlags, useFlag } from '../hooks/useFlag';
import {
  InsightsRedirect,
  InsightsRedirectScheduledEmails
} from '../pages/Analytics/Redirects';
import { inventorySettingsRoutes } from '../pages/ConfigurationCentre/InventorySettings/routes';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { RateTypes } from '../pages/ConfigurationCentre/RateTypes';

const Dashboard = lazyRetry(() => import('../pages/Dashboard'), 'Dashboard');
const Restrictions = lazyRetry(
  () => import('../pages/Restrictions/StrategicRestrictions'),
  'Restrictions'
);
const Groups = lazyRetry(() => import('../pages/Groups'), 'Groups');

const Analytics = lazyRetry(() => import('../pages/Analytics'), 'Analytics');
const Insights = lazyRetry(() => import('../pages/Insights'), 'Insights');

const RestrictionsLoader = lazyRetry(
  () => import('../pages/Restrictions/RestrictionsLoader'),
  'RestrictionsLoader'
);

const InventorySettingsContainer = lazyRetry(
  () =>
    import(
      '../pages/ConfigurationCentre/InventorySettings/InventorySettingsContainer'
    ),
  'InventorySettingsContainer'
);

const RatePlanPowerEditorContainer = lazyRetry(
  () =>
    import(
      '../pages/ConfigurationCentre/RatePlanPowerEditor/RatePlanPowerEditorContainer'
    ),
  'RatePlanPowerEditorContainer'
);

const ConfigurationCenterContainer = lazyRetry(
  () => import('../pages/ConfigurationCentre/ConfigurationCenterContainer'),
  'ConfigurationCentre'
);

const TeamManagementContainer = lazyRetry(
  () =>
    import(
      '../pages/ConfigurationCentre/TeamManagement/TeamManagementContainer'
    ),
  'TeamManagement'
);

const DefaultPriceControls = lazyRetry(
  () =>
    import(
      '../pages/ConfigurationCentre/DefaultPriceControls/DefaultPriceControls'
    ),
  'DefaultPriceControls'
);

const SegmentsContainer = lazyRetry(
  () => import('../pages/ConfigurationCentre/Segments/SegmentsContainer'),
  'SegmentsContainer'
);

const FXRatesPage = lazyRetry(
  () => import('../pages/ConfigurationCentre/FixedFXRates'),
  'FXRatesPage'
);

const GroupsQuotationConfigPageLegacy = lazyRetry(
  () => import('../pages/ConfigurationCentre/GroupsQuotationLegacy'),
  'GroupsQuotationPageLegacy'
);

const GroupsQuotationConfigPage = lazyRetry(
  () => import('../pages/ConfigurationCentre/GroupsQuotation'),
  'GroupsQuotationConfigPage'
);

const DataEnrichmentManagement = lazyRetry(
  () => import('../pages/ConfigurationCentre/DataEnrichment'),
  'DataEnrichmentManagement'
);

const Pricing = lazyRetry(() => import('../pages/Pricing'), 'Pricing');
const Calendar = lazyRetry(
  () => import('../pages/Calendar/Calendar'),
  'Calendar'
);

const Planning = lazyRetry(() => import('../pages/Planning'), 'Planning');

const CreateSplitTest = lazyRetry(
  () =>
    import(
      '../pages/ConfigurationCentre/SplitTests/CreateSplitTest/CreateSplitTest'
    ),
  'CreateSplitTest'
);

const SplitTestsContainer = lazyRetry(
  () => import('../pages/ConfigurationCentre/SplitTests/SplitTestsContainer'),
  'SplitTestsContainer'
);

const RateShopperContainer = lazyRetry(
  () => import('../pages/ConfigurationCentre/RateShopper/RateShopperContainer'),
  'RateShopperContainer'
);

const StrategicRestrictionsContainer = lazyRetry(
  () =>
    import(
      '../pages/ConfigurationCentre/Restrictions/StrategicRestrictionsContainer'
    ),
  'StrategicRestrictionsContainer'
);

const PROPERTY_REDIRECT_MAP = [
  {
    originalPath: '/properties/:propertyName/restrictions',
    newPath: `/restrictions/${RestrictionsViewType.Effective}`
  },
  {
    originalPath: '/properties/:propertyName/groups',
    newPath: `/groups`
  },
  {
    originalPath: '/configuration/default-price-controls/:propertyName',
    newPath: `/configuration/default-price-controls`
  },
  {
    originalPath: '/properties/:propertyName/analytics',
    newPath: '/analytics'
  }
];

export const AuthenticatedRoutes = () => {
  const { canViewProperty, isAdmin } = usePermissions();
  const currentUser = useCurrentUser();
  const isEnnismoreUser =
    currentUser?.propertyGroupId === '59de73f6-1cac-479a-b3fa-a96080d30d23';
  const ennismoreInsightsV2Enabled = useFlag(FeatureFlags.InsightsV2Ennismore);
  const isOmniEnabled = isEnnismoreUser
    ? ennismoreInsightsV2Enabled
    : !!currentUser; // make sure currentUser is defined to avoid false positive flag
  const showLegacyInsights = useFlag(FeatureFlags.ShowLegacyInsights);
  const newRestrictionFramework = useFlag(
    FeatureFlags.NewRestrictionsFramework
  );
  const groupsProfitabilityEnabled = useFlag(FeatureFlags.GroupProfitability);
  const showRateTypes = useFlag(FeatureFlags.PricingMultipleRatePlans);

  const isAllowedProperty = useCallback(
    ({ property }: { [PropertyIDQueryParameterName]: string }) =>
      canViewProperty({ id: property }),
    [canViewProperty]
  );

  const showSplitTests = useFlag(FeatureFlags.SplitTests);

  return (
    <Router>
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        <Layout>
          <Suspense
            fallback={
              <FullPageCentered>
                <Spinner />
              </FullPageCentered>
            }
          >
            <Switch>
              <Route exact path="/" render={() => <Dashboard />} />
              <Route path="/optimization/:page?" render={() => <Pricing />} />
              <Redirect from="/pricing/:page?" to="/optimization/:page?" />
              {PROPERTY_REDIRECT_MAP.filter(
                ({ newPath }) => newPath !== '/groups'
              ).map(property => (
                <Route
                  key={property.originalPath}
                  exact
                  path={property.originalPath}
                  render={() => (
                    <PropertyRedirectRoute
                      newPath={property.newPath}
                      originalPath={property.originalPath}
                    />
                  )}
                />
              ))}
              {newRestrictionFramework && (
                <Redirect
                  from="/restrictions/:view?/:timeframe?"
                  to="/configuration/restrictions/:timeframe?"
                />
              )}
              <ProtectedRoute
                path="/restrictions/:view?/:timeframe?"
                render={() => (
                  <RestrictionsLoader>
                    <Restrictions />
                  </RestrictionsLoader>
                )}
                isAllowed={isAllowedProperty}
              />
              <Route path="/groups" render={() => <Groups />} />
              <Route
                path="/configuration/rate-plans"
                render={() => <RatePlanPowerEditorContainer />}
              />
              <ProtectedRoute
                path={inventorySettingsRoutes.root}
                render={() => <InventorySettingsContainer />}
                isAllowed={isAllowedProperty}
              />
              <Route
                path="/configuration/segments"
                render={() => <SegmentsContainer />}
              />
              <ProtectedRoute
                path="/configuration/default-price-controls"
                render={() => <DefaultPriceControls />}
                isAllowed={props => isAllowedProperty(props)}
              />
              <Route
                path="/configuration/data-enrichment"
                component={DataEnrichmentManagement}
              />
              <Route path="/planning" component={Planning} />
              <ProtectedRoute
                path="/configuration/split-tests/new"
                render={() => <CreateSplitTest />}
                isAllowed={showSplitTests}
              />
              <ProtectedRoute
                path="/configuration/split-tests"
                render={() => <SplitTestsContainer />}
                isAllowed={showSplitTests}
              />
              <Route
                path="/configuration/rate-shopper"
                render={() => <RateShopperContainer />}
              />

              <ProtectedRoute
                path="/configuration/fixed-exchange-rates"
                render={() => <FXRatesPage />}
                isAllowed={isAdmin()}
              />

              <ProtectedRoute
                path="/configuration/rate-types"
                render={() => <RateTypes />}
                isAllowed={isAdmin() && showRateTypes}
              />

              <Route path="/calendar/:view?" component={Calendar} />
              <ProtectedRoute
                path="/configuration/restrictions/:timeframe?"
                render={() => (
                  <RestrictionsLoader>
                    <StrategicRestrictionsContainer />
                  </RestrictionsLoader>
                )}
                isAllowed={newRestrictionFramework}
              />

              <Route
                path="/configuration/groups-quotation"
                render={() =>
                  groupsProfitabilityEnabled ? (
                    <GroupsQuotationConfigPage />
                  ) : (
                    <GroupsQuotationConfigPageLegacy />
                  )
                }
              />
              <Route
                path="/configuration/team-management/:view"
                render={() => <TeamManagementContainer />}
              />
              <Redirect
                exact
                from="/configuration/team-management"
                to="/configuration/team-management/activated-users"
              />
              <Route
                path="/configuration"
                render={() => <ConfigurationCenterContainer />}
              />
              {showLegacyInsights && (
                <Route path="/insights" component={Analytics} />
              )}
              {isOmniEnabled && (
                <Route path="/insights-v2" component={Insights} />
              )}
              <Redirect from="/login" to="/" />
              <Route path="/analytics" render={InsightsRedirect} />
              <Route path="/embed" render={InsightsRedirectScheduledEmails} />
              <Redirect from="/revenue-planning" to="/planning" />
              <Redirect from="/properties/redirect" to="/" />
              <Redirect from="/budget-management" to="/planning" />
              <Route path="*" component={NotFound} />
            </Switch>
          </Suspense>
        </Layout>
      </QueryParamProvider>
    </Router>
  );
};
