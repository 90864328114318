import { useMemo } from 'react';
import { AccessLevel, usePropertyListQuery } from '../__generated__/graphql';

export const usePropertiesList = (
  minimumAccessLevel: AccessLevel,
  onCompleted?: () => void
) => {
  const { data, loading, error } = usePropertyListQuery({
    onCompleted
  });

  const properties = useMemo(
    () =>
      data?.propertyGroup?.properties.filter(({ permissions }) => {
        if (minimumAccessLevel === AccessLevel.Full) {
          return permissions?.accessLevel === AccessLevel.Full;
        }

        if (minimumAccessLevel === AccessLevel.ReadOnly) {
          return (
            permissions?.accessLevel === AccessLevel.Full ||
            permissions?.accessLevel === AccessLevel.ReadOnly
          );
        }

        return true;
      }),
    [data, minimumAccessLevel]
  );
  const propertiesIds = properties?.map(p => p.id);

  return { properties, propertiesIds, loading, error };
};
