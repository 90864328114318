import { Skeleton } from 'antd';
import styled from 'styled-components';

export const StyledSkeleton = styled(Skeleton)<{ withSubtitle: boolean }>`
  .ant-skeleton-paragraph {
    margin-block-start: 12px !important;
  }

  .ant-skeleton-content {
    align-items: center;
    display: ${props => (props.withSubtitle ? 'unset' : 'flex')};
  }
`;

export const TitleWrapper = styled.div<{ fontSize: number }>`
  color: ${props => props.theme.colors.text.default};
  font-size: ${props => props.fontSize}px;
  font-weight: 500;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TitleContentsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  line-height: 24px;
`;

export const ExtraWrapper = styled.div`
  margin-left: 12px;
  display: flex;
  align-items: center;
  font-weight: 400;
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.typography.fontSize.sm}px;
  font-weight: 400;
  line-height: 24px;
  height: 24px;
`;
