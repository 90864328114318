import { useTheme } from 'styled-components';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Layout } from 'antd';
import { PageHeader } from './PageHeader/PageHeader';
import { Tab, Tabs } from '../../theme/components/NavigationTabs';
import {
  TabsWrapper,
  StyledContentWrapper,
  StyledWrapper
} from './PageContent.styles';
import { LocationDescriptor } from './PageHeader/PageHeader.types';
import { PageContentTitle } from './PageContentTitle/PageContentTitle';

type Props = {
  children: React.ReactNode;
  title: React.ReactNode;
  titleExtra?: React.ReactNode;
  subtitle?: React.ReactNode;
  subtitleExtra?: React.ReactNode;
  siderContent?: React.ReactNode;
  siderCollapsed?: boolean;
  loading?: boolean;
  tabs?: React.ComponentProps<typeof NavLink>[];
  tabsExtra?: React.ReactNode;
  headerRight?: React.ReactNode;
  backButtonTo?: LocationDescriptor;
  disableBackButton?: boolean;
  headerStyle?: React.CSSProperties;
  tabsStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  className?: string;
  onBackButtonClick?: () => void;
};

export const PageContent = ({
  children,
  backButtonTo,
  disableBackButton,
  onBackButtonClick,
  title,
  titleExtra,
  subtitle,
  subtitleExtra,
  loading,
  siderContent,
  siderCollapsed,
  tabs,
  tabsExtra,
  headerRight,
  headerStyle,
  tabsStyle,
  contentStyle,
  className
}: Props) => {
  const theme = useTheme();

  const tabsComponent = tabs && (
    <TabsWrapper style={tabsStyle}>
      <Tabs>
        {tabs.map((tab, index) => (
          <Tab key={index} {...tab} />
        ))}
      </Tabs>
      {tabsExtra}
    </TabsWrapper>
  );

  return (
    <StyledWrapper className={className}>
      <Layout.Header>
        <PageHeader
          backButtonTo={backButtonTo}
          disableBackButton={disableBackButton}
          onBackButtonClick={onBackButtonClick}
          headerLeft={
            <PageContentTitle
              loading={loading}
              title={title}
              titleExtra={titleExtra}
              subtitle={subtitle}
              subtitleExtra={subtitleExtra}
              backButtonTo={backButtonTo}
            />
          }
          headerRight={headerRight}
          style={headerStyle}
        />
      </Layout.Header>
      <Layout>
        {tabsComponent}
        <StyledContentWrapper $hasTabs={!!tabs} style={contentStyle}>
          {children}
        </StyledContentWrapper>
        {siderContent && (
          <Layout.Sider
            collapsible
            collapsedWidth={0}
            width={288}
            collapsed={siderCollapsed}
            trigger={null}
            style={{ background: theme.colors.white }}
          >
            {siderContent}
          </Layout.Sider>
        )}
      </Layout>
    </StyledWrapper>
  );
};
