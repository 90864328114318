import { InMemoryCache, ApolloClient, from } from '@apollo/client';
import { transactionId } from './constants';
import {
  authLink,
  sentryTransactionIdLink,
  debugHeadersLink,
  errorLink,
  retryLink,
  httpLink
} from './graphqlLink';
import { possibleTypes, typePolicies } from './utils/apolloClient';

const cache = new InMemoryCache({ possibleTypes, typePolicies });

export const client = new ApolloClient({
  uri: GRAPHQL_URL,
  cache,
  name: 'dashboard',
  version: RELEASE || 'development',
  link: from([
    authLink,
    sentryTransactionIdLink(transactionId),
    debugHeadersLink(),
    errorLink,
    retryLink,
    httpLink
  ])
});
