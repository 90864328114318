import { useMemo } from 'react';
import { gql } from '@apollo/client';
import { useGetCurrentUserQuery } from '../__generated__/graphql';
import { addDebugInfoToWindow } from '../utils/debug';

gql`
  query GetCurrentUser {
    propertyGroup {
      id
      name
    }
    currentUser {
      id
      email
      role
      isDemoUser
    }
  }
`;

export const useCurrentUser = ({ skip }: { skip?: boolean } = {}) => {
  const { data } = useGetCurrentUserQuery({ skip });

  const currentUser = useMemo(() => {
    if (!data?.currentUser || !data?.propertyGroup) {
      return undefined;
    }

    return {
      id: data.currentUser.id,
      email: data.currentUser.email,
      role: data.currentUser.role,
      isDemoUser: data.currentUser.isDemoUser,
      propertyGroupId: data.propertyGroup.id,
      propertyGroupName: data.propertyGroup.name
    };
  }, [data]);

  addDebugInfoToWindow('userId', currentUser?.id);
  addDebugInfoToWindow('propertyGroupId', currentUser?.propertyGroupId);

  return currentUser;
};
