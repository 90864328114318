import React from 'react';
import styled from 'styled-components';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { Button, ButtonProps } from 'antd';

const StyledCloseOutlined = styled(CloseOutlined)`
  font-size: 1rem;
  color: ${props => props.theme.colors.icon.default};
  &:hover {
    color: ${props => props.theme.colors.icon.hover};
  }
`;

const StyledButton = styled(Button)`
  padding: 0;
  height: 16px;
  width: 16px;
`;

export const CloseIcon = (props: ButtonProps) => (
  <StyledButton icon={<StyledCloseOutlined />} type="link" {...props} />
);
