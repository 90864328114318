import React from 'react';
import styled from 'styled-components';
import InfoCircleFilled from '@ant-design/icons/InfoCircleFilled';
import { useWarningBannerVisibility } from './useWarningBannerVisibility';
import { StyledWrapper, StyledCloseOutlined } from './WarningBanner.styles';

const StyledInfoCircleFilled = styled(InfoCircleFilled)`
  margin-right: 6px;
  color: ${props => props.theme.colors.warning.default};
`;

export const WarningBanner = () => {
  const [isWarningBannerVisible, setWarningBannerVisible] =
    useWarningBannerVisibility();

  if (!isWarningBannerVisible) {
    return null;
  }

  return (
    <StyledWrapper>
      <StyledInfoCircleFilled />
      Attention, production environment! You&apos;re logged in as an Internal
      Admin. Any changes you make will affect the customer directly.
      <StyledCloseOutlined onClick={() => setWarningBannerVisible(false)} />
    </StyledWrapper>
  );
};
