import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import { Spinner } from '../theme/components';
import { usePageTitle } from '../hooks/usePageTitle';
import { SSO } from '../pages/Login/SSO';
import { FullPageCentered } from '../theme/styles/styles';
import { lazyRetry } from '../utils/lazyRetry';
import { pages } from '../utils/urls';

const Login = lazyRetry(() => import('../pages/Login'), 'Login');
const PasswordReset = lazyRetry(
  () => import('../pages/PasswordReset/PasswordReset'),
  'PasswordReset'
);

const Container = ({ children }: { children?: React.ReactNode }) => {
  usePageTitle();
  return <>{children}</>;
};

export const UnauthenticatedRoutes = () => (
  <Router>
    <Suspense
      fallback={
        <FullPageCentered>
          <Spinner />
        </FullPageCentered>
      }
    >
      <Container>
        <Switch>
          <Route exact path={`/${pages.login}/:tenant`}>
            <SSO />
          </Route>
          <Route exact path={`/${pages.login}`}>
            <Login />
          </Route>
          <Route path={[`/${pages.passwordReset}`, `/${pages.signUp}`]}>
            <PasswordReset />
          </Route>
          <Redirect from="*" to={`/${pages.login}`} />
        </Switch>
      </Container>
    </Suspense>
  </Router>
);
