import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LocationState } from '../../../types';

export const StyledWrapper = styled.div`
  padding: ${props => `7px ${props.theme.padding.content}px`};
  border-bottom: 1px solid ${props => props.theme.colors.border.default};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledLink = styled(Link<LocationState>)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 16px;
  min-width: 32px;
  height: 32px;
  border-radius: 4px;

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  background-color: transparent;
  transition: background-color 0.1s linear;

  :hover {
    background-color: ${props =>
      props.disabled ? 'unset' : props.theme.colors.primary.backgroundHover};
  }

  svg {
    width: 16px;
    height: 16px;
    color: ${props =>
      props.disabled
        ? props.theme.colors.text.disabled
        : props.theme.colors.primary.default};
  }
`;

export const BackButtonWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  min-height: 40px;
`;
