import React, { useCallback } from 'react';
import { Button, Drawer } from 'antd';
import { useHistory } from 'react-router-dom';
import { useAtom } from 'jotai';
import { useNotificationUtils, VeltNotificationsPanel } from '@veltdev/react';
import { createGlobalStyle } from 'styled-components';
import { Flex } from '../../theme/components';
import {
  commentAnnotationIdAtom,
  showNotificationsAtom
} from '../../store/showNotificationsAtom';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { TrackingEventNames } from '../../tracking/trackingNames';

const DrawerStyles = createGlobalStyle`
  .app-notifications-drawer-mask {
    background: none !important;
  }
`;

export const NotificationsDrawer = () => {
  const trackEvent = useTrackEvent();
  const [showNotifications, setShowNotifications] = useAtom(
    showNotificationsAtom
  );
  const [, setCommentAnnotationId] = useAtom(commentAnnotationIdAtom);
  const { push } = useHistory();
  const notificationsElement = useNotificationUtils();

  const goToNotificationSource = useCallback(
    (notification: unknown) => {
      if (
        typeof notification === 'object' &&
        notification !== null &&
        'notificationSource' in notification &&
        notification.notificationSource === 'comment'
      ) {
        if (
          'notificationSourceData' in notification &&
          typeof notification.notificationSourceData === 'object' &&
          notification.notificationSourceData !== null &&
          'annotationId' in notification.notificationSourceData &&
          typeof notification.notificationSourceData.annotationId ===
            'string' &&
          'pageInfo' in notification.notificationSourceData &&
          typeof notification.notificationSourceData.pageInfo === 'object' &&
          notification.notificationSourceData.pageInfo !== null &&
          'commentUrl' in notification.notificationSourceData.pageInfo
        ) {
          const url = notification.notificationSourceData.pageInfo.commentUrl;

          if (typeof url === 'string') {
            const urlObj = new URL(url);
            urlObj.searchParams.delete('scommentId');
            const path = urlObj.pathname + urlObj.search;
            push(path);
            setCommentAnnotationId(
              notification.notificationSourceData.annotationId
            );
            setShowNotifications(false);
          }
        }
      }
    },
    [push, setShowNotifications, setCommentAnnotationId]
  );

  const markAllAsRead = useCallback(() => {
    notificationsElement?.setAllNotificationsAsRead();
    trackEvent({
      name: TrackingEventNames.collaboration.notificationsMarkAllAsRead
    });
  }, [notificationsElement, trackEvent]);

  return (
    <>
      <DrawerStyles />
      <Drawer
        title="Notifications"
        open={showNotifications}
        placement="left"
        width={448}
        zIndex={99}
        onClose={() => setShowNotifications(false)}
        destroyOnClose
        className="app-notifications-drawer"
        getContainer={false}
        classNames={{
          mask: 'app-notifications-drawer-mask'
        }}
        footer={
          <Flex justifyContent="flex-end">
            <Button onClick={markAllAsRead}>Mark all as read</Button>
          </Flex>
        }
      >
        <VeltNotificationsPanel
          onNotificationClick={goToNotificationSource}
          variant="drawer-content"
          shadowDom={false}
          tabConfig={{
            forYou: {
              enable: true
            },
            documents: {
              enable: false
            },
            people: {
              enable: false
            },
            all: {
              enable: true
            }
          }}
        />
      </Drawer>
    </>
  );
};
