import { useAtom } from 'jotai';
import { useContext } from 'react';
import { GlobalStoreContext, GlobalStoreContextType } from '../../../store';
import { isWarningBannerVisibleAtom } from '../../../pages/Dashboard/store';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { UserRole } from '../../../__generated__/graphql';
import { PaceEnvironment } from '../../../constants';
import { paceEnvironment } from '../../../config';

export const useWarningBannerVisibility = () => {
  const globalStore = useContext<GlobalStoreContextType>(GlobalStoreContext);
  const [isWarningBannerVisible, setWarningBannerVisible] = useAtom(
    isWarningBannerVisibleAtom,
    { store: globalStore }
  );
  const currentUser = useCurrentUser();

  const warningBannerPreconditions =
    currentUser?.role === UserRole.PaceAdmin &&
    !currentUser?.isDemoUser &&
    paceEnvironment === PaceEnvironment.Prod &&
    // Disable for FLYR3 property group for ITB demo purposes
    currentUser?.propertyGroupId !== '7b6d5105-4656-4d0d-941e-4b7e31c08e13';

  return [
    warningBannerPreconditions && isWarningBannerVisible,
    setWarningBannerVisible
  ] as const;
};
