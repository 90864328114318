import { Layout } from 'antd';
import styled from 'styled-components';

export const StyledWrapper = styled(Layout)`
  height: 100vh;

  header {
    background: ${props => props.theme.colors.white};
    padding: 0;
    height: unset;
    line-height: unset;
  }
`;

export const StyledContentWrapper = styled(Layout.Content)<{
  $hasTabs: boolean;
}>`
  padding: ${props =>
    props.$hasTabs
      ? `0 ${props.theme.padding.content}px 16px ${props.theme.padding.content}px`
      : `16px ${props.theme.padding.content}px`};
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${props => `24px ${props.theme.padding.content}px`};
`;
