import { useMemo } from 'react';
import { ArrayParam, useQueryParam, withDefault } from 'use-query-params';
import { PropertyIDQueryParameterName } from '../constants';

export const useSelectedPropertyIds = () => {
  const [params, setParams] = useQueryParam(
    PropertyIDQueryParameterName,
    withDefault(ArrayParam, [])
  );

  // You can theoretically have a querystring ?p=null&p=1 - this filters out null/undefined values
  const propertyIds = useMemo(
    () => params.filter(Boolean) as string[],
    [params]
  );

  return [propertyIds, setParams] as const;
};
