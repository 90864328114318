import { useTheme } from 'styled-components';
import React from 'react';
import { Box } from '../../../theme/components';
import { LocationDescriptor } from '../PageHeader/PageHeader.types';
import {
  StyledSkeleton,
  TitleWrapper,
  TitleContentsWrapper,
  ExtraWrapper,
  SubtitleWrapper
} from './PageContentTitle.style';

interface Props {
  title: React.ReactNode;
  titleExtra?: React.ReactNode;
  subtitle?: React.ReactNode;
  subtitleExtra?: React.ReactNode;
  loading?: boolean;
  backButtonTo?: LocationDescriptor;
}

export const PageContentTitle = ({
  loading,
  title,
  titleExtra,
  subtitle,
  subtitleExtra,
  backButtonTo
}: Props) => {
  const theme = useTheme();

  if (loading) {
    return (
      <Box width={300}>
        <StyledSkeleton
          active
          title={{ width: 300 }}
          paragraph={{ rows: 1 }}
          withSubtitle={subtitle != null}
        />
      </Box>
    );
  }

  if (!title && !titleExtra) {
    return null;
  }

  return (
    <TitleWrapper
      fontSize={
        titleExtra && backButtonTo
          ? theme.typography.fontSize.lg
          : theme.typography.fontSize.xxl
      }
    >
      <TitleContentsWrapper>
        {title}
        {titleExtra && <ExtraWrapper>{titleExtra}</ExtraWrapper>}
      </TitleContentsWrapper>
      {subtitle && (
        <SubtitleWrapper>
          {subtitle}
          {subtitleExtra && <ExtraWrapper>{subtitleExtra}</ExtraWrapper>}
        </SubtitleWrapper>
      )}
    </TitleWrapper>
  );
};
