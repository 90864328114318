import React from 'react';
import { useLocation } from 'react-router-dom';
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import { Tooltip } from 'antd';
import { LocationState } from '../../../types';
import { Box, Flex } from '../../../theme/components';
import { WarningBanner } from '../WarningBanner/WarningBanner';
import { useConfigurationCenterLocation } from './useConfigurationCenterLocation';
import { LocationDescriptor } from './PageHeader.types';
import {
  StyledLink,
  BackButtonWrapper,
  StyledWrapper
} from './PageHeader.styles';

type Props = {
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  backButtonTo?: LocationDescriptor;
  disableBackButton?: boolean;
  style?: React.CSSProperties;
  onBackButtonClick?: () => void;
};

export const PageHeader = ({
  headerLeft,
  headerRight,
  backButtonTo,
  disableBackButton,
  onBackButtonClick,
  style
}: Props) => {
  const configurationCenterLocation = useConfigurationCenterLocation();
  const location = useLocation<LocationState>();

  const backButtonToValue = backButtonTo || configurationCenterLocation;
  const fromLabel =
    location.state?.fromLabel ??
    backButtonTo?.state?.fromLabel ??
    'Back to Configuration';

  return (
    <>
      <WarningBanner />
      {(backButtonToValue || headerLeft || headerRight) && (
        <StyledWrapper style={style}>
          {backButtonToValue && (
            <BackButtonWrapper>
              <Tooltip
                open={disableBackButton ? false : undefined}
                title={fromLabel}
              >
                <StyledLink
                  aria-label="Go back"
                  component={disableBackButton ? Box : undefined}
                  disabled={disableBackButton}
                  to={backButtonToValue}
                  onClick={onBackButtonClick}
                >
                  <ArrowLeftOutlined />
                </StyledLink>
              </Tooltip>
            </BackButtonWrapper>
          )}
          {(headerLeft || headerRight) && (
            <Flex
              justifyContent="space-between"
              alignItems="center"
              backgroundColor="white"
              width="100%"
              minHeight="64px"
            >
              <Flex
                lineHeight={2}
                flex={1}
                height="100%"
                alignItems="center"
                gap={2}
              >
                {headerLeft}
              </Flex>
              <Flex height="100%" alignItems="center" gap={2}>
                {headerRight}
              </Flex>
            </Flex>
          )}
        </StyledWrapper>
      )}
    </>
  );
};
