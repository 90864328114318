import { useCallback } from 'react';
import { gql } from '@apollo/client';
import { useTrackEventMutation } from '../__generated__/graphql';
import { TrackingEventNamesType } from '../tracking/trackingNames';
import { paceEnvironment } from '../config';
import { PaceEnvironment } from '../constants';

gql`
  mutation TrackEvent($payload: JSON!) {
    trackEvent(payload: $payload)
  }
`;

export const useTrackEvent = () => {
  const [mutate] = useTrackEventMutation({
    fetchPolicy: 'no-cache'
  });

  const track = useCallback(
    (params: { name: TrackingEventNamesType } & Record<string, unknown>) => {
      if (paceEnvironment === PaceEnvironment.Prod) {
        mutate({
          variables: {
            payload: {
              title: document.title,
              url: window.location.href,
              host: window.location.hostname,
              referrer: document.referrer,
              device_width: window.screen.width,
              device_height: window.screen.height,
              ...params
            }
          },
          ignoreResults: true
        });
      }
    },
    [mutate]
  );

  return track;
};
