import { useLocation, useRouteMatch } from 'react-router-dom';
import { LocationState } from '../../../types';
import { getPropertyIdFromLocationSearch } from '../../../utils/property';

export const useConfigurationCenterLocation = () => {
  const location = useLocation<LocationState>();
  const propertyId = getPropertyIdFromLocationSearch(location.search) || '';
  const fromPath = location.state?.fromPath;

  const configurationPageMatch = useRouteMatch(`/configuration/*`);

  if (!configurationPageMatch) {
    return undefined;
  }

  if (fromPath) {
    const urlObj = new URL(fromPath, window.location.href);
    const searchParams = urlObj.search || `?property=${propertyId}`;

    return {
      pathname: urlObj.pathname,
      search: propertyId ? searchParams : undefined
    };
  }

  return {
    pathname: `/configuration`,
    searchParams: `property=${propertyId}`
  };
};
