import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Stack } from '..';

type TabsProps = {
  children?: React.ReactNode;
  className?: string;
};

export const Tabs = ({ children, className }: TabsProps) => (
  <Stack alignItems="center" minHeight="42px" className={className}>
    {children}
  </Stack>
);

type TabProps = {
  fontSize?: string;
};

export const Tab = styled(NavLink)<TabProps>`
  align-items: center;
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  display: flex;
  font-size: ${props => props.fontSize || '16px'};
  font-weight: 500;
  height: 100%;
  color: unset;
  margin-right: 40px;
  min-height: inherit;

  &.active {
    border-bottom-color: ${props => props.theme.colors.primary.default};
    color: ${props => props.theme.colors.primary.default};
  }
`;
