import { CalendarViewEntriesQuery } from '../../__generated__/graphql';

export enum CalendarViewType {
  Month = 'month',
  Year = 'year',
  List = 'list'
}

export type CalendarEntryEventsByDay<T> = {
  event?: T[];
  seasonalEvent?: T[];
  propertyClosure?: T[];
  notes?: T[][];
};

type ExcludeUnusedSettings<T> = T extends {
  __typename?: 'DefaultPriceSetting' | 'SplitTest';
}
  ? never
  : T;

export type CalendarItem = ExcludeUnusedSettings<
  CalendarViewEntriesQuery['calendarEntriesByDate'][number]
>;
