import ApiClient from './ApiClient';
import AdminAPI from './Admin';
import SessionsAPI from './Sessions';
import PriceManagementAPI from './PriceManagement';

const apiFactory = ({
  apiPrefix,
  options
}: {
  apiPrefix: string;
  options: { transactionId: string };
}) => {
  if (!apiPrefix) {
    throw new Error('[apiPrefix] required');
  }

  const v1API = new ApiClient({ prefix: apiPrefix, options });
  const v2API = new ApiClient({ prefix: 'v2/', options });

  return {
    admin: new AdminAPI({ apiClient: v1API }),
    adminV2: new AdminAPI({ apiClient: v2API }),
    priceManagement: new PriceManagementAPI({ apiClient: v2API }),
    sessions: new SessionsAPI({ apiClient: v1API })
  };
};

export default apiFactory;
