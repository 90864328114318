import startCase from 'lodash/startCase';
import { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

export const usePageTitle = () => {
  const { pathname } = useLocation();
  const configPropertyMatch = useRouteMatch<{ page: string }>(
    `/configuration/:page/:propertyName?`
  );
  const revenuePlanningMatch = useRouteMatch<{ page: string }>(
    `/planning/:page`
  );

  const groupsQuotationMatch = useRouteMatch<{ page: string }>(
    `/groups/quotation/:quoteId`
  );
  const insightsV2Match = useRouteMatch<{ page: string }>(`/insights-v2`);

  const groupsListMatch = useRouteMatch<{ tab: string }>(`/groups/:tab`);

  useEffect(() => {
    if (pathname) {
      let pageName = '';
      if (configPropertyMatch) {
        pageName = `Configuration · ${startCase(
          configPropertyMatch?.params?.page
        )}`;
      } else if (revenuePlanningMatch) {
        pageName = `Planning · ${startCase(
          revenuePlanningMatch?.params?.page
        )}`;
      } else if (groupsQuotationMatch) {
        pageName = `Quotation · Groups`;
      } else if (groupsListMatch) {
        pageName = `Groups`;
      } else if (insightsV2Match) {
        pageName = `Insights 2.0`;
      } else {
        const splitPathList = pathname.split('/');
        pageName = splitPathList[splitPathList.length - 1];
        if (pageName === '') {
          pageName = 'home';
        }
        pageName = startCase(pageName);
      }
      document.title = `${pageName} · FLYR for Hospitality`;
    }
  }, [
    configPropertyMatch,
    groupsListMatch,
    groupsQuotationMatch,
    insightsV2Match,
    pathname,
    revenuePlanningMatch
  ]);
};
