export const pages = {
  property: 'property',
  actions: 'actions',
  pricing: 'pricing',
  restrictions: 'restrictions',
  calendar: 'calendar',
  settings: 'settings',
  priceSettings: 'price-settings',
  groups: 'groups',
  insights: 'insights',
  signUp: 'sign-up',
  login: 'login',
  passwordReset: 'reset-password',
  properties: 'properties',
  strategicControls: 'strategic-controls'
};

export const auth = 'auth';

export const roomsSettings = 'rooms-settings';

export const getCleanUrlRootPath = (url: string) => {
  const withoutParams = url.split('?')[0];
  const firstRootElement = withoutParams.split('/')[1];
  return `/${firstRootElement}`;
};
