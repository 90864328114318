import styled from 'styled-components';
import { CloseIcon } from '../../../theme/icons/CloseIcon';

export const StyledCloseOutlined = styled(CloseIcon)`
  position: absolute;
  right: 24px;
`;

export const WARNING_BANNER_HEIGHT = 50;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: ${WARNING_BANNER_HEIGHT}px;
  background: ${props => props.theme.colors.plain.yellow90};
  padding: 30px 0;
`;
