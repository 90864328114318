import React, { useEffect, useMemo } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { PageContent } from '../../../components/PageContent';
import { Box, Flex, TreeMultiSelect } from '../../../theme/components';
import { useSelectedPropertyIds } from '../../../hooks/useSelectedPropertyIds';
import { AccessLevel } from '../../../__generated__/graphql';
import { usePropertiesList } from '../../../hooks/usePropertiesList';
import { TreeMultiSelectOption } from '../../../theme/components/TreeMultiSelect';

enum Routes {
  RateTypes = '/configuration/rate-types',
  Mapping = '/configuration/rate-types/mapping'
}

export const RateTypes = () => {
  const [selectedPropertyIds, setSelectedPropertyIds] =
    useSelectedPropertyIds();

  const { properties = [], propertiesIds = [] } = usePropertiesList(
    AccessLevel.Full
  );

  const location = useLocation();
  const isSelectedViewRateTypes = useMemo(
    () => location.pathname === Routes.RateTypes,
    [location.pathname]
  );

  const actionButton = useMemo(
    () =>
      isSelectedViewRateTypes ? (
        <Button type="primary">New Rate Type</Button>
      ) : (
        <Button type="primary">Bulk Edit</Button>
      ),
    [isSelectedViewRateTypes]
  );

  useEffect(() => {
    if (!isSelectedViewRateTypes && !selectedPropertyIds.length) {
      setSelectedPropertyIds(propertiesIds);
    }
  }, [
    isSelectedViewRateTypes,
    propertiesIds,
    selectedPropertyIds.length,
    setSelectedPropertyIds
  ]);

  const mapToTreeMultiSelect: TreeMultiSelectOption[] = propertiesIds?.map(
    i => ({ key: i, title: properties.find(p => p.id === i)?.name, value: i })
  );

  return (
    <PageContent
      title="Rate Types"
      headerRight={
        !isSelectedViewRateTypes && (
          <Box width={275}>
            <TreeMultiSelect
              onChange={setSelectedPropertyIds}
              selected={selectedPropertyIds}
              selectedLabel={['Property', 'Properties']}
              flattenSelection
              virtual={false}
              aria-label="Selected properties"
              options={mapToTreeMultiSelect}
            />
          </Box>
        )
      }
      tabs={[
        {
          to: Routes.RateTypes,
          exact: true,
          children: 'Rate Types'
        },
        {
          to: Routes.Mapping,
          children: 'Mapping'
        }
      ]}
      tabsExtra={actionButton}
    >
      <Flex flexDirection="column" height="100%">
        <Route exact path={Routes.RateTypes}>
          RateTypes
        </Route>
        <Route path={Routes.Mapping}>Mapping</Route>
      </Flex>
    </PageContent>
  );
};
