import { InfoCircleOutlined } from '@ant-design/icons';
import { Switch, Tooltip } from 'antd';
import { TooltipProps } from 'antd/es/tooltip';
import styled from 'styled-components';
import React from 'react';
import { Box } from '../Box';
import { Flex } from '../Flex';

type ToggleProps = {
  checked: boolean;
  disabled?: boolean;
  loading?: boolean;
  title?: string;
  label?: string;
  onChange: (checked: boolean) => void;
  tooltipText?: string;
  tooltipPropsOverride?: TooltipProps;
  ariaLabel?: string;
  iconPosition?: 'left' | 'right';
  color?: string;
};

const StyledInfoIcon = styled(InfoCircleOutlined)<{ color: string }>`
  color: ${({ color }) => color};
`;

const ToggleComponent = ({
  checked,
  title,
  disabled,
  loading,
  tooltipText,
  tooltipPropsOverride,
  ariaLabel,
  color = 'inherit',
  iconPosition = 'left',
  onChange
}: ToggleProps) => (
  <Flex alignItems="center">
    {iconPosition === 'left' && tooltipText && (
      <Box marginY="auto" mr={2}>
        <Tooltip
          placement="top"
          title={tooltipText}
          overlayStyle={{ maxWidth: '21em' }}
          {...tooltipPropsOverride}
        >
          <StyledInfoIcon color={color} />
        </Tooltip>
      </Box>
    )}
    <Box marginY="auto">
      <Switch
        id={title}
        aria-label={ariaLabel}
        disabled={disabled}
        title={title}
        checkedChildren="On"
        unCheckedChildren="Off"
        checked={checked}
        onChange={onChange}
        loading={loading}
      />
    </Box>
    {iconPosition === 'right' && tooltipText && (
      <Flex alignItems="center" ml={2}>
        <Tooltip
          placement="top"
          title={tooltipText}
          overlayStyle={{ maxWidth: '21em' }}
          {...tooltipPropsOverride}
        >
          <StyledInfoIcon color={color} />
        </Tooltip>
      </Flex>
    )}
  </Flex>
);

export const Toggle = (props: ToggleProps) => {
  if (props.label) {
    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label>
        <Flex>
          <Box mr={2}>{props.label}</Box>
          <ToggleComponent {...props} />
        </Flex>
      </label>
    );
  }

  return <ToggleComponent {...props} />;
};
