import React from 'react';
import FlagOutlined from '@ant-design/icons/FlagOutlined';
import HomeOutlined from '@ant-design/icons/HomeOutlined';
import EnvironmentOutlined from '@ant-design/icons/EnvironmentOutlined';
import TrophyOutlined from '@ant-design/icons/TrophyOutlined';
import LineChartOutlined from '@ant-design/icons/LineChartOutlined';
import CustomerServiceOutlined from '@ant-design/icons/CustomerServiceOutlined';
import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import { nanoid } from 'nanoid';
import { CalendarEntryType, DayOfWeek } from './__generated__/graphql';
import { TaxAmountIndexes } from './pages/ConfigurationCentre/GroupsQuotation/types';

export const forRendering = {
  analytics: {
    dates: {
      format: 'DD/MM/YYYY',
      formatShort: 'DD/MM',
      formatMonth: 'MMM YYYY'
    }
  }
};

export const forDates = {
  useISOFormat: 'YYYY-MM-DD',
  useFullMonth: 'MMMM',
  useAbrevMonth: 'MMM',
  useFullYear: 'YYYY',
  useShortDayOfWeek: 'dd',
  useDayOfWeek: 'ddd',
  useDayOfMonth: 'D',
  useMonth: 'MM',
  useMonthAndYear: 'MMM yyyy',
  useDay: 'DD',
  useLocalizedShortDate: 'DD MMM',
  useLocalizedShortDateWithDayOfWeek: 'ddd DD MMM',
  useLocalizedDate: 'DD MMM YYYY',
  useLocalizedDateTime: 'DD MMM YYYY HH:mm',
  useLocalizedFullDate: 'ddd DD MMM YYYY'
};

export enum PaceEnvironment {
  Prod = 'prod',
  NonProd = 'non-prod'
}

export const AnalyticsType = {
  Dashboard: 'dashboard',
  Explore: 'explore',
  Looks: 'looks'
};

export const AnalyticsUrls = {
  dashboard: 'dashboards',
  explore: 'explore/Pace_Analytics',
  looks: 'looks'
};

export const EmbedPrefix = 'embed';

export const AnalyticsQueryParameterName = 'q';
export const PropertyIDQueryParameterName = 'property';

export const defaultCurrency = 'eur';

export const CalendarEntryCategories = {
  Other: { title: 'Other', icon: <CalendarOutlined /> },
  Music: { title: 'Music', icon: <CustomerServiceOutlined /> },
  Conference: { title: 'Conference', icon: <LineChartOutlined /> },
  Sports: { title: 'Sports', icon: <TrophyOutlined /> },
  Local: { title: 'Local', icon: <EnvironmentOutlined /> },
  InHouse: { title: 'In-house', icon: <HomeOutlined /> },
  Holiday: { title: 'Holiday', icon: <FlagOutlined /> }
};

export const FirebaseError = {
  userNotFound: 'auth/user-not-found',
  invalidLoginCredentials: 'auth/invalid-login-credentials'
};

export const calendarEntryDisplayNames: { [key in CalendarEntryType]: string } =
  {
    [CalendarEntryType.Note]: 'Note',
    [CalendarEntryType.PropertyClosure]: 'Property Closure',
    [CalendarEntryType.EventPriceSetting]: 'Event Price Settings',
    [CalendarEntryType.SeasonalPriceSetting]: 'Seasonal Price Settings',
    [CalendarEntryType.DefaultPriceSetting]: 'Default Price Settings',
    [CalendarEntryType.SplitTest]: 'Split Test'
  };

export const transactionId = nanoid();

export const DAYS_OF_WEEK = {
  [DayOfWeek.Mon]: 'Monday',
  [DayOfWeek.Tue]: 'Tuesday',
  [DayOfWeek.Wed]: 'Wednesday',
  [DayOfWeek.Thu]: 'Thursday',
  [DayOfWeek.Fri]: 'Friday',
  [DayOfWeek.Sat]: 'Saturday',
  [DayOfWeek.Sun]: 'Sunday'
};

export const TAX_SETTINGS_LABELS: Record<
  TaxAmountIndexes,
  {
    vatIndex: number;
    key: TaxAmountIndexes;
    label: string;
  }
> = {
  standard: { vatIndex: 0, key: 'standard', label: 'Standard' },
  reduced1: { vatIndex: 1, key: 'reduced1', label: 'Reduced 1' },
  reduced2: { vatIndex: 2, key: 'reduced2', label: 'Reduced 2' },
  superReduced: { vatIndex: 3, key: 'superReduced', label: 'Super Reduced' }
};
