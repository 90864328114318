export const addDebugInfoToWindow = (
  key: 'userId' | 'propertyGroupId' | 'logRocketSessionURL',
  value: unknown
) => {
  if (!window.__DEBUG__) {
    window.__DEBUG__ = {};
  }

  window.__DEBUG__[key] = value;
};
